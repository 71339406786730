<template>
  <div
    :class="[
      'fold_comm',
      { 'fold_pagination' : isSize }
    ]"
    ref="foldComm">
    <template v-if="dataList">
      <FoldItem
        v-for="(itemData, index) in dataList"
        :key="`foldItem${index}`"
        :index="index"
        :isFoldItemIndex.sync="isFoldItemIndex"
        :itemData="itemData"
        :orderNum="orderNum"
        :listStyle="listStyle"
        :iconTitle="iconTitle"
        :title="title"
        :desc="desc"/>
    </template>
    <template v-else>
      <slot name="fold_items" />
    </template>
    <div
       v-if="isSize && searchParams.page +1 < paginationData.totalPage"
      class="area_btn">
      <button class="btn_more" @click="$emit('onSearch')">더보기</button>
    </div>
  </div>
</template>
<script>
import FoldItem from './FoldItem';

export default {
  name: 'FoldList',
  props:{
    dataList:Array,
    orderNum: String,
    listStyle: String,
    iconTitle: String,
    title: String,
    desc: String,
    currentUnFoldItem: Number,
    isFoldItemIndexSlotType: Number,

    isSize:{
      type: Boolean,
      default: false
    },
    searchParams: Object,
    paginationData: Object,
  },
  components:{
    FoldItem,
  },
  computed:{
    isSlotType(){
      return Boolean(this.dataList)
    },
    isFoldItemIndexSlotTypeSync:{
      get(){
        return this.isFoldItemIndexSlotType;
      },
      set(val){
        this.$emit('update:isFoldItemIndexSlotType',val)
      },
    },
    searchParamsSync:{
      get(){
        return this.searchParams;
      },
      set(value){
        this.$emit('update:searchParams',value)
      }
    },
  },
  mounted(){
    this.isFoldItemIndex = this.currentUnFoldItem;
    this.setFoldItemTop();
  },
  data(){
    return{
      isFoldItemIndex:null,
      foldItemTopList:[],
    }
  },
  watch:{
    isFoldItemIndex(newVal){
      if(newVal != null){
        this.setScrollOpenItem()
      }
    },
    isFoldItemIndexSlotTypeSync(newVal){
      if(newVal != null){
        this.setScrollOpenItem()
      }
    },
    dataList(newVal){
      if(newVal.length > 0){
        this.isFoldItemIndex = null;
        this.isFoldItemIndexSlotTypeSync = null;
        this.setFoldItemTop();
      }
    },
  },
  methods:{
    getValueOfField(obj, field){
      // field의 값이 잘못되었다면 그냥 null 리턴.
      if (field === null || field === "" || typeof field === "undefined") {
        return null;
      }
      
      // .이 존재하는지를 체크하기 위해 pos 를 구함.
      const pos = field.indexOf(".");

      // '.'이 없다면 바로 리턴.
      if (pos === -1) {
        return obj[field];
      }

      // .이 있다면 다음 요소 탐색
      const nextObj = obj[field.slice(0, pos)];
      const nextField = field.slice(pos + 1);

      // nextObj가 배열일 경우 하나하나에 대한 하위 필드를 모아서 리턴하도록 함.
      if (Array.isArray(nextObj)) {
        return nextObj.map((item) => this.getValueOfField(item, nextField));
      }

      // 배열이 아닐 경우에는 그냥 하위 필드를 재귀하여 리턴
      return this.getValueOfField(nextObj, nextField);
    },
    setFoldItemTop(){
      this.$nextTick(() => {
        const listEl = this.$refs.foldComm;
        if(!listEl) return;

        const itmeElList = Array.from(listEl.children);
        if(!itmeElList) return;
        itmeElList.forEach((item,index) => {
          this.foldItemTopList[index] = item.offsetTop
        })
      });
    },
    setScrollOpenItem(){
      this.$nextTick(() => {
        const wrapEl = document.getElementsByClassName('wrap_firfin')[0];
        const isFullWrap = wrapEl ? Array.from(wrapEl.classList).indexOf('wrap_full') > -1 : false;
        const index = this.dataList ? this.isFoldItemIndex : this.isFoldItemIndexSlotTypeSync
        const scrollObj = {
          top: this.foldItemTopList[index] - ( isFullWrap ? 0 : 54), 
          behavior: 'smooth'
        };
        setTimeout(function () {
          window.scrollTo(scrollObj);
        }, 200);
      });
    },
    onChangePage(toPageIndex){
      this.searchParamsSync.page = toPageIndex;
      this.$emit('onSearch');
    },
  }
}
</script>
<style scoped>
  .area_btn{padding:24px 20px 0}
  .btn_more{display:block;width:100%;padding:14px;font-weight:700;font-size:18px;line-height:28px;border-radius:4px;background-color:#F3F1F1;color:#232323}
</style>