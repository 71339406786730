<template>
  <SectionBasic
    id="safe"
    :isActive="isActive">
    <h2 class="screen_out">안전한 퍼핀</h2>
    <div class="list_card">
      <div class="box_safe">
        <strong class="tit_safe">부모님이 직접<br>카드신청/분실신고/해지 가능</strong>
        <div class="area_img area_img1">
          <div class="inner_img"></div>
        </div>
      </div>
      <div class="box_safe">
        <strong class="tit_safe">안전한 모니터링과<br>알림 발송</strong>
        <p class="desc_safe">결제불가 · 1만원 초과 결제 시 알림</p>
        <div class="area_img area_img2">
          <div class="inner_img"></div>
        </div>
      </div>
      <div class="box_safe">
        <strong class="tit_safe">KB국민카드 전가맹점 결제</strong>
        <p class="desc_safe">청소년 유해업종시설 · 상품권 <span class="txt_break_response">결제 불가</span></p>
      </div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name: 'SectionSafe',
  props:{
    isActive: Boolean
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_safe{background-color:#000000}

.section_safe .box_safe{max-width:640px;margin:0 auto;padding:24px 0;border-radius:8px;background-color:#232323;text-align:center}
.section_safe .box_safe + .box_safe{margin-top:24px}

.section_safe .area_img{width:100%;max-width:320px;margin:0 auto -24px}
.section_safe .area_img1 .inner_img{padding-top:75%;background-image:url(/assets/images/card/img_safe1_230414.jpg)}
.section_safe .area_img2 .inner_img{padding-top:46.25%;background-image:url(/assets/images/card/img_safe2_230414.jpg)}
.section_safe .area_img .inner_img{background-repeat:no-repeat;background-size:contain}

.section_safe .tit_safe{display:block;padding:0 32px;font-weight:700;font-size:20px;line-height:28px;color:#fff}
.section_safe .desc_safe{margin-top:8px;padding:0 32px;font-weight:500;font-size:14px;line-height:22px;color:#8F69DD}
</style>