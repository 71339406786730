<template>
  <PageWithLayout
    :isTitle="true"
    :isBackBtn="true"
    :isBackWebEnd="true"
    :bottomPadding="104"
    :isBodyFull="true">
    <h2
      class="tit_missing"
      v-if="viewModel.loaded">{{ missingInfo.title }}</h2>
    <div class="box_card_info">
      <!-- <div class="area_left">
        <div class="img_card"></div>
      </div> -->
      <div
        class="area_overflow area_middle"
        v-if="viewModel.loaded">
        <div class="inner_middle">
          <span :class="['badge_status', `badge_${missingInfo.status}`]">{{ missingInfo.statusText }}</span>
          <p class="desc_card_num">{{ viewModel.cardInfo.card_number }}</p>
        </div>
      </div>
    </div>
    <FoldList
      v-if="viewModel.loaded"
      :dataList="missingInfo.infoDataList"
      title="title"
      desc="desc"
      :currentUnFoldItem="0"/>
    <div
      class="foot_lemontree"
      v-if="viewModel.loaded">
      <div class="inner_foot">
        <div class="group_btn">
          <template v-if="isMissing">
            <Button
              btnStyle="primary"
              :text="$poData.find_a_card_btn_resume"
              @onClickBtn="sendInterface('missingCard','CANCEL','missingType')" />
          </template>
          <template v-else>
            <Button
              btnStyle="tertiary_tonal"
              :text="$poData.missing_a_card_btn"
              @onClickBtn="sendInterface('missingCard','REPORT','missingType')" />
          </template>
        </div>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import FoldList from '@/components/common/fold/FoldList';
import Button from '@/components/common/button/Button';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin'
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import CardMissingViewModel from '@/views/card/viewModel/CardMissingViewModel';

export default {
  name: 'CardRegister',
  mixins: [ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    FoldList,
    Button
  },
  beforeMount(){
    const childUid = this.$route.query.childUid
    this.viewModel.init(childUid);
  },
  computed:{
    isMissing(){
      return this.viewModel.cardInfo.card_status === 'SUSPENDED'
    },
    missingInfo(){
      return this.isMissing ? this.viewModel.missingInfo.OFF : this.viewModel.missingInfo.ON
    }
  },
  data(){
    return{
      viewModel: new CardMissingViewModel(),
    }
  },
  methods:{
    onClickBackKey(){
      this.sendInterface('webEnd');
    }
  }
}
</script>

<style scoped>
.tit_missing{padding:16px 20px 0;font-weight:700;font-size:24px;line-height:30px;color:#000}

.box_card_info{overflow:hidden;margin:16px 20px 0;padding:16px 20px;border-radius:8px;background-color:#F7F6FA}
/* .box_card_info .img_card{width:58px;height:94px;background:url(/assets/images/card_missing/img_card.png)no-repeat 0 0;background-size:58px 94px} */
/* .box_card_info .area_middle{height:94px;padding-left:20px} */
.box_card_info .badge_status{display:inline-block;padding:4px 8px;border-radius:100px;font-weight:600;font-size:12px;line-height:16px;vertical-align:top}
.box_card_info .badge_on{color:#579BF5;background-color:#E6F3FE}
.box_card_info .badge_off{color:#E23C3C;background-color:#FDEFEF}
.box_card_info .desc_card_num{margin-top:8px;font-family:'Poppins';font-weight:600;font-size:14px;line-height:22px;color:#111}

.fold_comm{margin-top:16px}
.fold_comm >>> .body_fold .inner_body_fold ul li + li{margin-top:14px}

/* foot */
.foot_lemontree .inner_foot{position:absolute;bottom:0;left:0;right:0;padding:24px 20px 32px}
</style>